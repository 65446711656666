import {
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import { LayoutBlank } from 'components/layout/LayoutBlank';
import Logo from 'components/header/Logo';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
export default function VerifyUser() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id');
  const timestamp = searchParams.get('timestamp');
  const signature = searchParams.get('signature');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const BACKEND_API = process.env.REACT_APP_BACKEND_BASE!;
    const api = `${BACKEND_API}auth/verify-registration/`;
    fetch(api, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "user_id": user_id, "timestamp": timestamp, "signature": signature })
    })
    .then(response => response.json())
    .then(data => {
      setMessage(data.detail);
    })
    .catch(error => {
      console.error(error);
      setMessage('An error occurred. Please try again later.');
    });
  });

  return (
    <LayoutBlank>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            pt: 8,
            pb: 1,
            borderRadius: '4px',
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Logo width="200px" color="black" />
          </Box>
          <Typography align="center" sx={{ mb: 4 }}>
            {message}
          </Typography>

          <Link href="/signin">Sign in</Link>
        </Box>
      </Container>
    </LayoutBlank>
  );
}
