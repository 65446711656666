import { useLocation } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { FunctionComponent } from 'react';
import LinkRouter from '../ui/LinkRouter';
import BreadcrumbsSettings from './BreadcrumbsSettings';

const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1);
};

const NavBreadcrumbs: FunctionComponent = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const indexPath = pathNames[2];
  const mainPath = pathNames[1];
  const subPath = pathNames[3];
  const subPath2 = pathNames[4];
  const subPath3 = pathNames[5];

  if (mainPath === 'settings2') return <></>;
  if (mainPath === 'settings') return <BreadcrumbsSettings />;

  return (
    <MuiBreadcrumbs>
      <HomeIcon color="disabled" />
      <LinkRouter
        underline="hover"
        color="inherit"
        to={`/${mainPath}/${indexPath}`}
      >
        {capitalize(mainPath.replaceAll('-', ' '))}
      </LinkRouter>
      {subPath && (
        <LinkRouter
          underline="hover"
          color="inherit"
          to={`/${mainPath}/${indexPath}/${subPath}`}
        >
          {capitalize(subPath.replaceAll('-', ' '))}
        </LinkRouter>
      )}
      {subPath2 && (
        <LinkRouter
          underline="hover"
          color="inherit"
          to={`/${mainPath}/${indexPath}/${subPath}/${subPath2}`}
        >
          {capitalize(subPath2.replaceAll('-', ' '))}
        </LinkRouter>
      )}
      {subPath3 && (
        <LinkRouter
          underline="hover"
          color="inherit"
          to={`/${mainPath}/${indexPath}/${subPath}/${subPath2}/${subPath3}`}
        >
          {capitalize(subPath3.replaceAll('-', ' '))}
        </LinkRouter>
      )}
    </MuiBreadcrumbs>
  );
};

export default NavBreadcrumbs;
