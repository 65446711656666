import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { postWorkbenchActionConfirm } from 'apis/workbench';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import useWBCols from 'hooks/useWBCols';

export type RowSelectorMethodType = 'None' | 'TopN' | 'Sample';

type Props = {
  open: boolean;
  opporId?: number;
  actionConfirmParam: any;
  isSupportRowSelector?: boolean;
  onClose: () => void;
  onSave: (body?: any) => void;
};
const ActionApplyDialog: FunctionComponent<Props> = ({
  open,
  opporId,
  actionConfirmParam,
  isSupportRowSelector,
  onClose,
  onSave,
}) => {
  const { curIndex } = useUser();
  const { source, action, field, backQuery, labelGroupId } = actionConfirmParam;
  const { columns } = useWBCols({ source });
  const { data: dataActionConfirm, isLoading } = useQuery(
    [
      curIndex,
      'WBActionConfirm',
      source,
      action,
      field,
      backQuery,
      labelGroupId,
      opporId,
    ],
    () =>
      postWorkbenchActionConfirm(
        curIndex!.value,
        {
          back_query: backQuery,
          action,
          field,
          label_group_id: labelGroupId,
          opporId,
        },
        source
      ),
    { enabled: Boolean(action) && Boolean(field) }
  );
  const warningMsg = dataActionConfirm?.warning_message;

  const submitHandler = (e: React.FormEvent) => {
    let selector = undefined;
    if (rowSelectorMethod !== 'None') {
      selector = {
        method: rowSelectorMethod,
        col: selectedCol,
        rowNum,
        order,
      };
    }
    e.preventDefault();
    onSave({ selector });
  };

  const [rowSelectorMethod, setRowSelectorMethod] =
    useState<RowSelectorMethodType>('None');
  const [rowNum, setRowNum] = useState(1000);
  const [selectedCol, setSelectedCol] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const handleSetRowNum = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = +e.target.value;
    if (value < 1) value = 1;
    if (value > 10000) value = 10000;
    setRowNum(value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon color="info" /> Apply the action?
        </DialogTitle>
        <DialogContent>
          <Typography>
            The action will be applied. Are you sure you want to continue?
          </Typography>
          {isLoading && <LoadingSpinner />}
          {warningMsg && (
            <Typography
              sx={{ mt: 2, whiteSpace: 'pre-line' }}
              color="error"
              variant="body2"
            >
              {warningMsg}
            </Typography>
          )}
          {isSupportRowSelector && (
            <Box sx={{ my: 4 }}>
              <Typography sx={{ fontWeight: 700 }}>Row Selector</Typography>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  select
                  size="small"
                  label="Method"
                  variant="standard"
                  value={rowSelectorMethod}
                  onChange={(e) =>
                    setRowSelectorMethod(
                      e.target.value as RowSelectorMethodType
                    )
                  }
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="TopN">Top N</MenuItem>
                  <MenuItem value="Sample">Sample</MenuItem>
                </TextField>
                {rowSelectorMethod !== 'None' && (
                  <TextField
                    InputProps={{ inputProps: { min: 1, max: 10000 } }}
                    size="small"
                    variant="standard"
                    label="Number of rows"
                    type="number"
                    helperText="1 ~ 10000"
                    value={rowNum}
                    onChange={handleSetRowNum}
                  />
                )}
                {rowSelectorMethod === 'TopN' && (
                  <TextField
                    sx={{ minWidth: 200 }}
                    select
                    size="small"
                    label="Column"
                    variant="standard"
                    defaultValue=""
                    value={selectedCol}
                    onChange={(e) => setSelectedCol(e.target.value)}
                  >
                    {columns.map((col) => (
                      <MenuItem key={col.field} value={col.field}>
                        {col.headerName || col.field}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {rowSelectorMethod === 'TopN' && (
                  <TextField
                    select
                    size="small"
                    label="Order"
                    variant="standard"
                    value={order}
                    onChange={(e) => setOrder(e.target.value as 'asc' | 'desc')}
                  >
                    <MenuItem value="asc">asc</MenuItem>
                    <MenuItem value="desc">desc</MenuItem>
                  </TextField>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button color="inherit" type="submit">
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ActionApplyDialog;
