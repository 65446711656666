import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { WBSourceType } from 'types/types';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { getIndexFields } from 'apis/index_fields';
import { getAllFields } from 'components/settings2/SettingsFields';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { RowSelectorMethodType } from './ActionApplyDialog';
import useWBCols from 'hooks/useWBCols';

export type FieldOptionType = {
  field: string;
  label: string;
  enabled: boolean;
};

type FieldCheckBoxProps = {
  item: FieldOptionType;
  items: FieldOptionType[];
  setItems: React.Dispatch<React.SetStateAction<FieldOptionType[]>>;
};

const FieldCheckBox: FunctionComponent<FieldCheckBoxProps> = ({
  item,
  items,
  setItems,
}) => {
  const handleCheck = (e: any, checked: boolean) => {
    const newItems = items.map((_item) => {
      if (_item.field === item.field) {
        _item.enabled = checked;
        return _item;
      }
      return _item;
    });
    setItems(newItems);
  };
  return (
    <FormControlLabel
      key={item.field}
      control={<Checkbox checked={item.enabled} onChange={handleCheck} />}
      label={item.label}
    />
  );
};

type Props = {
  open: boolean;
  source: WBSourceType;
  onClose: () => void;
  onApply: (
    fieldOptions: FieldOptionType[],
    method: RowSelectorMethodType,
    numOfRows: number,
    numOfSplits: number,
    order: string,
    isCustomFields: boolean,
    selectedCol: string
  ) => void;
};
const ExportDlg: FunctionComponent<Props> = ({
  open,
  onClose,
  onApply,
  source,
}) => {
  const [rowSelectorMethod, setRowSelectorMethod] =
    useState<RowSelectorMethodType>('None');
  const [rowNum, setRowNum] = useState(1000);
  const [segment, setSegment] = useState(1);
  const { curIndex } = useUser();
  const [isCustomFields, setIsCustomFields] = useState(false);
  const [fieldOptions, setFieldOptions] = useState<FieldOptionType[]>([]);
  let { data: dataFields, isLoading } = useQuery(
    [curIndex, 'IndexFields'],
    () => getIndexFields(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 }
  );
  useEffect(() => {
    if (dataFields) {
      const fields = getAllFields(dataFields, source);
      // different variable can have same name, and we only want to show one
      const fieldSet = new Set<any>();
      const options: FieldOptionType[] = [];
      for (const item of fields) {
        if (fieldSet.has(item.field)) continue;
        fieldSet.add(item.field);
        options.push({
          field: item.field,
          label: item.label,
          enabled: item.enabled,
        });
      }
      setFieldOptions(options);
    }
  }, [dataFields, source]);

  const handleApply = () => {
    onApply(
      fieldOptions,
      rowSelectorMethod,
      rowNum,
      segment,
      order,
      isCustomFields,
      selectedCol
    );
  };

  const handleHideAll = () => {
    const _fieldOptions = fieldOptions.map((item) => ({
      ...item,
      enabled: false,
    }));
    setFieldOptions(_fieldOptions);
  };

  const [selectedCol, setSelectedCol] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const { columns } = useWBCols({ source });

  const handleSetRowNum = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = +e.target.value;
    if (value < 1) value = 1;
    if (value > 10000) value = 10000;
    setRowNum(value);
  };

  const title = !isCustomFields
    ? 'Export to Google Sheets'
    : 'Please select the ingredients you want to export';
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon color="info" /> {title}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCustomFields}
                  onChange={(e) => setIsCustomFields(e.target.checked)}
                />
              }
              label="Customize fields"
            />
            <Divider sx={{ my: 4 }} />
          </Box>
          {isCustomFields && (
            <Box>
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {fieldOptions.map((option) => (
                    <FieldCheckBox
                      item={option}
                      items={fieldOptions}
                      setItems={setFieldOptions}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
          {!isCustomFields && (
            <Box sx={{ my: 4 }}>
              <Typography sx={{ fontWeight: 700 }}>Row Selector</Typography>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  select
                  size="small"
                  label="Method"
                  variant="standard"
                  value={rowSelectorMethod}
                  onChange={(e) =>
                    setRowSelectorMethod(
                      e.target.value as RowSelectorMethodType
                    )
                  }
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="TopN">Top N</MenuItem>
                  <MenuItem value="Sample">Sample</MenuItem>
                </TextField>
                {rowSelectorMethod !== 'None' && (
                  <TextField
                    InputProps={{ inputProps: { min: 1, max: 10000 } }}
                    size="small"
                    variant="standard"
                    label="Number of rows"
                    type="number"
                    helperText="1 ~ 10000"
                    value={rowNum}
                    onChange={handleSetRowNum}
                  />
                )}
                {rowSelectorMethod === 'Sample' && (
                  <TextField
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                    size="small"
                    variant="standard"
                    label="Number of splits"
                    type="number"
                    value={segment}
                    onChange={(e) => setSegment(+e.target.value)}
                  />
                )}
                {rowSelectorMethod === 'TopN' && (
                  <TextField
                    sx={{ minWidth: 200 }}
                    select
                    size="small"
                    label="Column"
                    variant="standard"
                    defaultValue=""
                    value={selectedCol}
                    onChange={(e) => setSelectedCol(e.target.value)}
                  >
                    {columns.map((col) => (
                      <MenuItem key={col.field} value={col.field}>
                        {col.headerName || col.field}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {rowSelectorMethod === 'TopN' && (
                  <TextField
                    select
                    size="small"
                    label="Order"
                    variant="standard"
                    value={order}
                    onChange={(e) => setOrder(e.target.value as 'asc' | 'desc')}
                  >
                    <MenuItem value="asc">asc</MenuItem>
                    <MenuItem value="desc">desc</MenuItem>
                  </TextField>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
        {isCustomFields && (
          <Button variant="outlined" onClick={handleHideAll}>
            Hide All
          </Button>
        )}
        <Button variant="contained" onClick={handleApply}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDlg;
