import { v4 as uuid } from 'uuid';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { deepCopy, queryBuilderFields2OperationOptions } from 'utils';
import { COLUMN_REF_PREFIX } from 'constants/constants';
import MixTagsInput from '../shared/ui/MixTagsInput';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import {
  getFunctionIngredients,
  updateFunctionIngredient,
} from 'apis/function_ingredient';
import LoadingSpinner from '../shared/ui/LoadingSpinner';
import {
  ItemType as FunctionGredientItemType,
  TagText,
} from 'components/settings/views/function-ingredient/FunctionIngredientList';
import { useSnackbar } from 'contexts/snackbar-context';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAtom } from 'jotai';
import { WBColPreviewAtom } from 'store/jotai';

const FILTERED_LABEL_LIST = [
  'satisfies recipe',
  'not satisfies recipe',
  'url in segment',
  'url not in segment',
];

const PREDEFINED_PATTERNS = ['NUMBERS', 'SYMBOLS', 'SPACES'];

const OPERATION_ACTION_OPTIONS: OperationActionType[] = [
  'COMBINE_TEXT',
  'COMBINE_COLUMN',
  'REPLACE',
  'REMOVE',
  'SPLIT',
  'GET',
  'GET_FIRST',
  'GET_LAST',
  'SLICE',
  'JOIN',
  'REMOVE_LEADING',
  'CAPITILISE',
  'REMOVE_ELEMENT',
  'SWAP',
  'DECODE_URL',
  '+',
  '-',
  '*',
  '/',
  'ChatGPT',
];

const NO_SUBJECT_ACTIONS: OperationActionType[] = [
  'GET_FIRST',
  'GET_LAST',
  'DECODE_URL',
  'CAPITILISE',
  'COMBINE_TEXT',
  'COMBINE_COLUMN',
  'NUMERIC',
];

const NUMERIC_OPERATIONS: OperationActionType[] = ['+', '-', '*', '/'];

const HAS_PARAM_ACTIONS: OperationActionType[] = ['REPLACE', 'SLICE', 'SWAP'];

export type OperationOptionType = {
  label: string;
  type: OperationType;
  value: string;
};

type PayloadType = Partial<{
  operation: OperationProp;
  operationAction: OperationActionProp;
}>;

type ActionType =
  | 'ADD-OPERATION'
  | 'ADD-NUMERIC-OPERATION'
  | 'ADD-CHATGPT-OPERATION'
  | 'ADD-TEXT-PROMPT-OPERATION'
  | 'REMOVE-OPERATION'
  | 'UPDATE-OPERATION'
  | 'UPDATE-OPERATION-ACTION'
  | 'REMOVE-OPERATION-ACTION'
  | 'ADD-OPERATION-ACTION'
  | 'COMBINE-TEXT-BLUR--SUBJECT'
  | 'COMBINE-TEXT-BLUR--PARAM'
  | 'LOAD-OPERATION';
type ActionProp = {
  type: ActionType;
  payload?: PayloadType;
};

type OperationActionType =
  | 'COMBINE_TEXT'
  | 'COMBINE_COLUMN'
  | 'REPLACE'
  | 'REMOVE'
  | 'SPLIT'
  | 'GET'
  | 'JOIN'
  | 'GET_FIRST'
  | 'GET_LAST'
  | 'SLICE'
  | '+'
  | '-'
  | '*'
  | '/'
  | 'ChatGPT'
  | 'NUMERIC'
  | 'REMOVE_LEADING'
  | 'CAPITILISE'
  | 'REMOVE_ELEMENT'
  | 'SWAP'
  | 'DECODE_URL';

type OperationActionProp = {
  id: string;
  type: OperationActionType;
  subject: string;
  param?: string;
};
type OperationType = 'BLANK' | 'ARRAY' | 'CHAT-GPT' | 'NUMERIC' | 'TEXT-PROMPT';
type OperationProp = {
  id: string;
  type: OperationType;
  value?: string;
  actions?: OperationActionProp[];
  selectedVariable?: string;
};
export type StateProp = {
  operations: OperationProp[];
};

const INIT_STATE: StateProp = { operations: [] };

const handleAddOperation = (state: StateProp): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  return {
    ...state,
    operations: [...operations, { id: uuid(), type: 'BLANK' }],
  };
};

const handleAddNumericOperation = (state: StateProp): StateProp => {
  return {
    operations: [
      {
        id: uuid(),
        type: 'NUMERIC',
        value: 'Document',
        actions: [{ subject: '', id: uuid(), type: 'NUMERIC' }],
      },
    ],
  };
};

const handleAddChatGPTOperation = (state: StateProp): StateProp => {
  return {
    operations: [
      {
        id: uuid(),
        value: 'Document',
        type: 'CHAT-GPT',
        actions: [{ subject: '', id: uuid(), type: 'ChatGPT' }],
      },
    ],
  };
};

const handleAddTextPromptOperation = (state: StateProp): StateProp => {
  return {
    operations: [
      {
        id: uuid(),
        value: 'Document',
        type: 'TEXT-PROMPT',
        actions: [{ subject: '', id: uuid(), type: 'COMBINE_TEXT' }],
      },
    ],
  };
};

const handleRemoveOperation = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  return {
    ...state,
    operations: operations.filter((item) => item.id !== payload.operation!.id),
  };
};

const handleUpdateOperation = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  const idx = operations.findIndex((item) => item.id === operation.id);
  if (idx === -1) return state;
  if (operation.type === 'ARRAY') {
    const operationAction: OperationActionProp = {
      id: uuid(),
      type: 'REPLACE',
      param: '',
      subject: '',
    };
    operation.actions = [operationAction];
  } else if (operation.type === 'NUMERIC') {
    const operationAction: OperationActionProp = {
      id: uuid(),
      type: 'NUMERIC',
      param: '',
      subject: '',
    };
    operation.actions = [operationAction];
  } else if (operation.type === 'CHAT-GPT') {
    const operationAction: OperationActionProp = {
      id: uuid(),
      type: 'ChatGPT',
      param: '',
      subject: '',
    };
    operation.actions = [operationAction];
  } else {
    return state;
  }
  operations[idx] = operation;
  return { ...state, operations };
};

const handleUpdateOperationAction = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  const operationAction = payload.operationAction!;
  const opIdx = operations.findIndex((item) => item.id === operation.id);
  if (opIdx === -1) return state;
  const actions = operation.actions;
  if (!actions) return state;
  const actionIdx = actions.findIndex((item) => item.id === operationAction.id);
  if (actionIdx === -1) return state;
  actions[actionIdx] = operationAction;
  operation.actions = actions;
  operations[opIdx] = operation;
  return { ...state, operations };
};

const handleCombineTextBlur__SUBJECT = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  const operationAction = payload.operationAction!;
  const opIdx = operations.findIndex((item) => item.id === operation.id);
  if (opIdx === -1) return state;
  const actions = operation.actions;
  if (!actions) return state;
  const actionIdx = actions.findIndex((item) => item.id === operationAction.id);
  if (actionIdx === -1) return state;
  actions[actionIdx].subject = operationAction.subject;
  operations[opIdx].actions![actionIdx].subject = actions[actionIdx].subject;
  return { ...state, operations };
};

const handleCombineTextBlur__PARAM = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  const operationAction = payload.operationAction!;
  const opIdx = operations.findIndex((item) => item.id === operation.id);
  if (opIdx === -1) return state;
  const actions = operation.actions;
  if (!actions) return state;
  const actionIdx = actions.findIndex((item) => item.id === operationAction.id);
  if (actionIdx === -1) return state;
  actions[actionIdx].param = operationAction.param;
  operations[opIdx].actions![actionIdx].param = actions[actionIdx].param;
  return { ...state, operations };
};

const handleRemoveOperationAction = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  const operationAction = payload.operationAction!;
  const opIdx = operations.findIndex((item) => item.id === operation.id);
  if (opIdx === -1) return state;
  let actions = operation.actions;
  if (!actions) return state;
  actions = actions.filter((item) => item.id !== operationAction.id);
  operation.actions = actions;
  operations[opIdx] = operation;
  return { ...state, operations };
};

const handleAddOperationAction = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const operations = deepCopy(state.operations) as OperationProp[];
  const operation = deepCopy(payload.operation!) as OperationProp;
  if (!(operation.type.startsWith('ARRAY') || operation.type === 'CHAT-GPT'))
    return state;
  const idx = operations.findIndex((item) => item.id === operation.id);
  if (idx === -1) return state;
  const operationAction: OperationActionProp = {
    id: uuid(),
    type: 'REPLACE',
    param: '',
    subject: '',
  };
  if (!operation.actions || operation.actions?.length === 0) {
    operation.actions = [operationAction];
  } else {
    operation.actions.push(operationAction);
  }
  operations[idx] = operation;
  return { ...state, operations };
};

const handleLoadOperation = (
  state: StateProp,
  payload: PayloadType
): StateProp => {
  const _state = deepCopy(INIT_STATE) as StateProp;
  if (!payload.operation) return _state;
  const operation = deepCopy(payload.operation!) as OperationProp;
  if (Object.keys(operation).length === 0) return _state;
  _state.operations.push(operation);
  return _state;
};

const reducer = (state: StateProp, action: ActionProp) => {
  switch (action.type) {
    case 'ADD-OPERATION':
      return handleAddOperation(state);
    case 'ADD-NUMERIC-OPERATION':
      return handleAddNumericOperation(state);
    case 'ADD-CHATGPT-OPERATION':
      return handleAddChatGPTOperation(state);
    case 'ADD-TEXT-PROMPT-OPERATION':
      return handleAddTextPromptOperation(state);
    case 'REMOVE-OPERATION':
      return handleRemoveOperation(state, action.payload!);
    case 'UPDATE-OPERATION':
      return handleUpdateOperation(state, action.payload!);
    case 'UPDATE-OPERATION-ACTION':
      return handleUpdateOperationAction(state, action.payload!);
    case 'COMBINE-TEXT-BLUR--SUBJECT':
      return handleCombineTextBlur__SUBJECT(state, action.payload!);
    case 'COMBINE-TEXT-BLUR--PARAM':
      return handleCombineTextBlur__PARAM(state, action.payload!);
    case 'REMOVE-OPERATION-ACTION':
      return handleRemoveOperationAction(state, action.payload!);
    case 'ADD-OPERATION-ACTION':
      return handleAddOperationAction(state, action.payload!);
    case 'LOAD-OPERATION':
      return handleLoadOperation(state, action.payload!);
    default:
      return state;
  }
};

const TYPE_SUBJECT_SHOULD_NOT_EMPTY: OperationActionType[] = [
  'REPLACE',
  'REMOVE',
  'SPLIT',
  'GET',
];
const isSubjectError = (item: OperationActionProp) => {
  let ret = false;
  const subject = item.subject;
  const type = item.type;
  if (subject === '') {
    if (TYPE_SUBJECT_SHOULD_NOT_EMPTY.indexOf(type) !== -1) {
      ret = true;
    }
  }
  return ret;
};

const isParamError = (item: OperationActionProp) => {
  let ret = false;
  return ret;
};

type OperationItemActionProps = {
  operation: OperationProp;
  item: OperationActionProp;
  numberFields: FieldType[];
  operationOptions: OperationOptionType[];
  dispatch: React.Dispatch<ActionProp>;
};

const OperationItemAction: FC<OperationItemActionProps> = ({
  operation,
  item,
  numberFields,
  operationOptions,
  dispatch,
}) => {
  const actionType = item.type;
  const showSubject = NO_SUBJECT_ACTIONS.indexOf(actionType) === -1;
  const suggestions = [...PREDEFINED_PATTERNS];
  const showCombineText =
    actionType in { COMBINE_TEXT: true, COMBINE_COLUMN: true, NUMERIC: true };
  const handleCombineTextBlur = useCallback(
    (value) => {
      dispatch({
        type: 'COMBINE-TEXT-BLUR--SUBJECT',
        payload: {
          operation,
          operationAction: {
            ...item,
            subject: value || '',
          },
        },
      });
    },
    [dispatch, item, operation]
  );
  const handleChatGptPromptTemplateBlur = useCallback(
    (value) => {
      dispatch({
        type: 'COMBINE-TEXT-BLUR--PARAM',
        payload: {
          operation,
          operationAction: {
            ...item,
            param: value || '',
          },
        },
      });
    },
    [dispatch, item, operation]
  );
  let operationActionOptions = OPERATION_ACTION_OPTIONS;

  // https://linear.app/similarai/issue/APP-2215/numeric-operators-should-only-be-available-for-numeric-fields
  if (operation.value) {
    const isNumberField =
      numberFields.findIndex((item) => item.value === operation.value) !== -1;
    if (!isNumberField) {
      operationActionOptions = operationActionOptions.filter((option) => {
        if (NUMERIC_OPERATIONS.indexOf(option) === -1) return true;
        return false;
      });
    }
  }

  if (operation.type !== 'CHAT-GPT') {
    operationActionOptions = operationActionOptions.filter(
      (option) => option !== 'ChatGPT'
    );
  }

  const isChatGPT = actionType === 'ChatGPT';
  const { curIndex } = useUser();
  const { data: chatGptData, isLoading } = useQuery(
    [curIndex, 'FunctionIngredient'],
    () => getFunctionIngredients(curIndex!.value),
    { enabled: Boolean(curIndex) && isChatGPT }
  );

  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();

  const { mutate: m_update } = useMutation(
    (body: any) =>
      updateFunctionIngredient(curIndex!.value, item.subject, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([curIndex, 'FunctionIngredient']);
        setSnackbar({ severity: 'success', message: 'Updated' });
      },
    }
  );

  const handleClickSave = () => {
    //['params']['prompt_template']
    const prompt_template = item.param;
    const body = { params: { prompt_template } };
    m_update(body);
  };

  const [isEditFn, setIsEditFn] = useState(false);

  const handleClickEdit = () => {
    setIsEditFn(true);
  };

  const handleClickView = () => {
    setIsEditFn(false);
  };

  if (isChatGPT && isLoading) {
    return <LoadingSpinner />;
  }
  const showParam = HAS_PARAM_ACTIONS.indexOf(actionType) !== -1;
  const selectedChatGpt = chatGptData?.find(
    (_item: any) => _item.id.toString() === item.subject.toString()
  );

  const hiddenOperation =
    operation.type === 'NUMERIC' || operation.type === 'TEXT-PROMPT';

  const hasMultipleAction =
    operation.type.startsWith('ARRAY') || operation.type === 'CHAT-GPT';

  let whitelist = operationOptions.map((item) => ({
    _value: item.value,
    value: item.label,
  }));
  if (item.type === 'NUMERIC') {
    whitelist = numberFields.map((item) => ({
      _value: item.value,
      value: item.label,
    }));
  }
  let chatGPTInitVal = selectedChatGpt?.params?.prompt_template || '';
  // In case variable is loaded with chatGPT operation
  if (isChatGPT && !chatGPTInitVal) {
    const actions = operation.actions;
    if (actions) {
      const action = actions.find(
        (action) => action.subject === selectedChatGpt?.id
      );
      if (action) chatGPTInitVal = action.param;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        my: 1,
        mx: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box>--</Box>
        <TextField
          variant="standard"
          select
          size="small"
          sx={{
            maxWidth: 240,
            minWidth: 200,
            display: hiddenOperation ? 'none' : 'initial',
          }}
          value={actionType}
          onChange={(e) =>
            dispatch({
              type: 'UPDATE-OPERATION-ACTION',
              payload: {
                operation,
                operationAction: {
                  ...item,
                  type: e.target.value as OperationActionType,
                },
              },
            })
          }
        >
          {operationActionOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        {showCombineText && (
          <MixTagsInput
            onBlur={handleCombineTextBlur}
            whitelist={whitelist}
            initValue={item.subject}
          />
        )}
        {showSubject && !isChatGPT && (
          <Autocomplete
            options={suggestions}
            freeSolo
            value={item.subject}
            onChange={(e, newValue) =>
              dispatch({
                type: 'UPDATE-OPERATION-ACTION',
                payload: {
                  operation,
                  operationAction: {
                    ...item,
                    subject: newValue || '',
                  },
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                size="small"
                error={isSubjectError(item)}
                sx={{ width: 200 }}
                value={item.subject}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE-OPERATION-ACTION',
                    payload: {
                      operation,
                      operationAction: {
                        ...item,
                        subject: e.target.value,
                      },
                    },
                  })
                }
              />
            )}
          />
        )}
        {isChatGPT && !isEditFn && (
          <TextField
            size="small"
            variant="standard"
            sx={{ minWidth: 200 }}
            select
            value={item.subject}
            onChange={(e) =>
              dispatch({
                type: 'UPDATE-OPERATION-ACTION',
                payload: {
                  operation,
                  operationAction: {
                    ...item,
                    subject: e.target.value,
                    param: chatGptData.find(
                      (item: any) => item.id === e.target.value
                    )?.params?.prompt_template,
                  },
                },
              })
            }
          >
            {chatGptData.map((item: FunctionGredientItemType) => (
              <MenuItem key={item.id} value={item.id}>
                <TagText
                  rawStr={
                    item.id +
                    ' - ' +
                    (item.name ? item.name : item.params?.prompt_template || '')
                  }
                />
              </MenuItem>
            ))}
          </TextField>
        )}
        {showParam && (
          <TextField
            size="small"
            variant="standard"
            value={item.param || ''}
            error={isParamError(item)}
            onChange={(e) =>
              dispatch({
                type: 'UPDATE-OPERATION-ACTION',
                payload: {
                  operation,
                  operationAction: {
                    ...item,
                    param: e.target.value,
                  },
                },
              })
            }
          />
        )}
        {isChatGPT && isEditFn && (
          <MixTagsInput
            onBlur={handleChatGptPromptTemplateBlur}
            whitelist={operationOptions.map((item, idx) => ({
              id: idx,
              _value: item.value,
              value: item.label,
            }))}
            initValue={chatGPTInitVal}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 0.4 }}>
        {!isEditFn && selectedChatGpt && (
          <Tooltip title="Edit function ingredient">
            <IconButton onClick={handleClickEdit} color="info">
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {isEditFn && isChatGPT && (
          <Tooltip title="Cancel Edit">
            <IconButton onClick={handleClickView} color="info">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        {isEditFn && isChatGPT && (
          <Tooltip title="Save function ingredient">
            <IconButton onClick={handleClickSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}

        {hasMultipleAction && (
          <IconButton
            onClick={() =>
              dispatch({
                type: 'REMOVE-OPERATION-ACTION',
                payload: { operation, operationAction: item },
              })
            }
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

type OperationItemProps = {
  operationOptions: OperationOptionType[];
  numberFields: FieldType[];
  item: OperationProp;
  dispatch: React.Dispatch<ActionProp>;
};
const OperationItem: FC<OperationItemProps> = ({
  item,
  numberFields,
  dispatch,
  operationOptions,
}) => {
  const OPERATION_VALUE_TYPE_MAP: Record<string, OperationType> =
    operationOptions.reduce(
      (pre, cur) => ({ ...pre, [cur.label]: cur.type }),
      {}
    );
  const showField =
    item.type !== 'NUMERIC' &&
    item.type !== 'CHAT-GPT' &&
    item.type !== 'TEXT-PROMPT';
  return (
    <Paper
      sx={{
        p: 2,
        borderTop: '1px solid #ccc',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {showField && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              select
              variant="standard"
              size="small"
              sx={{ width: 200 }}
              defaultValue=""
              value={item.value || ''}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE-OPERATION',
                  payload: {
                    operation: {
                      ...item,
                      type: OPERATION_VALUE_TYPE_MAP[e.target.value],
                      value: e.target.value,
                    },
                  },
                })
              }
            >
              {operationOptions.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        <IconButton
          onClick={() =>
            dispatch({ type: 'REMOVE-OPERATION', payload: { operation: item } })
          }
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        {item.actions?.map((action) => (
          <OperationItemAction
            key={action.id}
            operation={item}
            numberFields={numberFields}
            item={action}
            operationOptions={operationOptions}
            dispatch={dispatch}
          />
        ))}
      </Box>
      {(item.type.startsWith('ARRAY') || item.type === 'CHAT-GPT') && (
        <Button
          startIcon={<AddIcon />}
          onClick={() =>
            dispatch({
              type: 'ADD-OPERATION-ACTION',
              payload: { operation: item },
            })
          }
        >
          Add Operation
        </Button>
      )}
    </Paper>
  );
};

type FieldType = {
  type: 'number' | 'text' | 'select' | 'boolean';
  label: string;
  value: string;
};

type Props = {
  fields: any;
  onApply: (operation: OperationProp) => void;
  onChange?: (state: StateProp) => void;
  loadedOperation?: OperationProp;
  setSaveOrApply: React.Dispatch<React.SetStateAction<'Save' | 'Apply'>>;
};

export const isChatGPTOperation = (state: StateProp | undefined) => {
  if (state && state.operations) {
    const operation = state.operations[0];
    if (operation && operation.type === 'CHAT-GPT') return true;
  }
  return false;
};

const OperationBuilder: FC<Props> = ({
  fields,
  onApply,
  onChange,
  loadedOperation,
  setSaveOrApply,
}) => {
  const _fields: {
    type: 'number' | 'text' | 'select' | 'boolean';
    label: string;
    value: string;
  }[] = [];
  for (const groupKey in fields) {
    const group = fields[groupKey];
    if ('subfields' in group) {
      for (const fieldKey in group.subfields) {
        const _field = group.subfields[fieldKey];
        _fields.push({
          type: _field.type,
          value: fieldKey,
          label: _field.label,
        });
      }
    }
  }
  const numberFields = _fields.filter((item) => item.type === 'number');
  const operationOptions: OperationOptionType[] = useMemo(() => {
    const _operationOptions = queryBuilderFields2OperationOptions(fields);
    return [
      ..._operationOptions.filter(
        (item) => FILTERED_LABEL_LIST.indexOf(item.label) === -1
      ),
      { label: '$previous result', value: '$previous_result', type: 'ARRAY' },
    ];
  }, [fields]);
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const handleApply = (saveOrApply: 'Save' | 'Apply') => {
    setSaveOrApply(saveOrApply);
    const _state = replaceLabel2Value(state);
    onApply(_state.operations[0]);
  };
  const operationLabelValueMap = useMemo(() => {
    const map = new Map<string, string>();
    for (const o of operationOptions) {
      map.set(o.label, o.value);
    }
    map.set('Document', 'document');
    map.set('ChatGPT', 'document');
    return map;
  }, [operationOptions]);

  const replaceLabel2Value = useCallback(
    (state: StateProp): StateProp => {
      const _state = deepCopy(state) as StateProp;
      for (const op of _state.operations) {
        if (!op.value) break;
        op.value = operationLabelValueMap.get(op.value!);
        for (const action of op.actions || []) {
          if (operationLabelValueMap.has(action.subject)) {
            action.subject =
              COLUMN_REF_PREFIX + operationLabelValueMap.get(action.subject);
          }
          if (action.param && operationLabelValueMap.has(action.param)) {
            action.param =
              COLUMN_REF_PREFIX + operationLabelValueMap.get(action.param);
          }
        }
      }
      return _state;
    },
    [operationLabelValueMap]
  );

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (onChange) {
        const _state = deepCopy(state) as StateProp;
        const operations = _state.operations;
        if (operations.length > 0) {
          const operation = operations[0];
          const actions = operation.actions;
          if (actions) {
            const _state = replaceLabel2Value(state);
            onChange(_state);
          }
        }
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [state, onChange, replaceLabel2Value]);

  useEffect(() => {
    if (loadedOperation) {
      if (
        loadedOperation.type === 'ARRAY' ||
        loadedOperation.type === 'CHAT-GPT'
      ) {
        if (loadedOperation.actions) {
          loadedOperation.actions = loadedOperation.actions.map((item) => ({
            ...item,
            id: uuid(),
          }));
        }
      }
      dispatch({
        type: 'LOAD-OPERATION',
        payload: { operation: loadedOperation },
      });
    }
  }, [loadedOperation]);

  const [, setColPreview] = useAtom(WBColPreviewAtom);
  useEffect(() => {
    if (state.operations.length > 0) {
      const isChatGpt = isChatGPTOperation(state);
      if (isChatGpt) {
        setColPreview('GPT');
      } else {
        setColPreview('Var');
      }
    } else {
      setColPreview('None');
    }
  }, [state, setColPreview]);

  const isOperationEmpty = state.operations.length === 0;

  return (
    <Box
      sx={{ p: 2, backgroundColor: '#eff1f9', border: '1px dashed #d6dae1' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button
          disabled={state.operations?.length > 0}
          startIcon={<AddIcon />}
          onClick={() => dispatch({ type: 'ADD-OPERATION' })}
        >
          Add Text Operation
        </Button>
      </Box>
      <Box>
        {state.operations.map((item: OperationProp) => (
          <OperationItem
            numberFields={numberFields}
            key={item.id}
            item={item}
            dispatch={dispatch}
            operationOptions={operationOptions}
          />
        ))}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={handleApply.bind(null, 'Apply')}
          disabled={isOperationEmpty}
        >
          Apply Variable
        </Button>
        <Button
          onClick={handleApply.bind(null, 'Save')}
          variant="outlined"
          disabled={isOperationEmpty}
        >
          Save Variable
        </Button>
      </Box>
    </Box>
  );
};

export default OperationBuilder;
