export const getSiteTopicReports = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/get-reports`
  );
  if (data?.data?.items) {
    data.data.items = data.data.items.sort((x: any, y: any) => y.id - x.id);
  }
  return data.data;
};

export const getSiteTopicReportItem = async (
  index: string | number,
  reportId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}`
  );
  return data.data;
};

export const getSiteTopicTopLabelGroups = async (
  index: string | number,
  reportId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-label-groups`
  );
  return data.data;
};

export const getSiteTopicTopLabelsByGroup = async (
  index: string | number,
  reportId: string | number,
  labelGroup: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/labels-by-group?label_group=${labelGroup}`
  );
  return data.data;
};

export const getSiteTopicTopLabels = async (
  index: string | number,
  reportId: string | number,
  labelGroup1: string,
  labelGroup2: string,
  label1?: string,
  label2?: string
) => {
  let params = `?label_group1=${labelGroup1}&label_group2=${labelGroup2}`;
  if (label1) params += `&label1=${label1}`;
  if (label2) params += `&label2=${label2}`;
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-labels${params}`
  );
  return data.data;
};

export const getSiteTopicTopPages = async (
  index: string | number,
  reportId: string | number,
  keywords: string
) => {
  let params = `?keywords=${keywords}`;
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-pages${params}`
  );
  return data.data;
};
