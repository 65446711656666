import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useUser } from 'contexts/user-context';
import { getSiteTopicTopLabels } from 'apis/site_topic';
import { renderArray } from 'components/shared/table-cell/ArrayCell';
import { renderTopPages } from './TopPagesCell';

const COLUMNS: GridColDef[] = [
  {
    field: 'label',
    headerName: 'Label',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderArray,
    width: 240,
  },
  {
    field: 'competitive_traffic',
    headerName: 'Competitive Traffic',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'total_volume',
    headerName: 'Total Volume',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 140,
  },
  {
    field: 'total_impression',
    headerName: 'Total Impression',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 140,
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderArray,
    width: 300,
    flex: 1,
  },
  {
    field: 'pages',
    headerName: 'Pages',
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderTopPages,
    width: 300,
    flex: 1,
  },
];

type Props = {
  report_id: string | number;
  labelGroup1: string;
  labelGroup2: string;
  columns: string[];
  label1?: string;
  label2?: string;
};

const MANDATORY_COLS = ['label', 'keywords', 'pages'];

const SiteTopicReportLabelGroupTable: FC<Props> = ({
  report_id,
  columns,
  labelGroup1,
  labelGroup2,
  label1,
  label2,
}) => {
  let cols = COLUMNS;
  if (columns.length > 0) {
    cols = cols.filter((col) => {
      if (MANDATORY_COLS.includes(col.field)) return true;
      if (columns.includes(col.field)) return true;
      return false;
    });
  }
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [
      curIndex,
      'site-topic',
      report_id,
      labelGroup1,
      labelGroup2,
      label1,
      label2,
    ],
    () =>
      getSiteTopicTopLabels(
        curIndex!.value!,
        report_id,
        labelGroup1,
        labelGroup2,
        label1,
        label2
      ),
    {
      enabled:
        Boolean(curIndex) && Boolean(labelGroup1) && Boolean(labelGroup2),
    }
  );
  const items = data?.items || [];
  let i = 0;
  for (const item of items) {
    item['id'] = i;
    i++;
    item['report_id'] = report_id;
    item['label'] = [item['label1'], item['label2']];
  }

  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{ pagination: { pageSize: 10, page: 0 } }}
          loading={isLoading}
          pagination
          rowsPerPageOptions={[10, 25, 100]}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={cols}
          rows={items}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default SiteTopicReportLabelGroupTable;
