import Layout from 'components/layout/Layout';
import SiteTopicReportLabelGroupMain from 'components/site-topic/detail/SiteTopicReportLabelGroupMain';
import { FC } from 'react';

const SiteTopicReportLabelGroupPage: FC = () => {
  return (
    <Layout>
      <SiteTopicReportLabelGroupMain />
    </Layout>
  );
};

export default SiteTopicReportLabelGroupPage;
