import { Box, Button, Menu } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getDocumentImportPresignedUrl,
  uploadFeedIngredients,
} from 'apis/feed_ingredient';
import { useSnackbar } from 'contexts/snackbar-context';
import { FC, useState } from 'react';
import UploadCSVTable from './UploadCSVTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { TextReader } from 'utils/text-reader';

type Props = {
  indexId: string;
};

export type DocumentType =
  | 'pf'
  | 'tx'
  | 'hi_tx'
  | 'pd'
  | 'kd'
  | 'hl'
  | 'pe'
  | 'gsc_bulk'
  | 'inv'
  | 'sf'
  | 'be';

export type DocumentOptionType = {
  label: string;
  value: DocumentType | 'pd--incremental';
};

export const REQUIRED_COLS: Record<DocumentType, string[]> = {
  pf: ['sku'],
  tx: [],
  hi_tx: [],
  pd: ['url'],
  kd: ['keyword'],
  hl: [],
  pe: [
    'URL',
    'Status Code',
    'Date Crawled Ts',
    'Page',
    'In Indexable Pages',
    'Is Indexable',
  ],
  gsc_bulk: [
    'url',
    'bulk_gsc_export_total_impressions',
    'bulk_gsc_export_total_clicks',
  ],
  sf: ['Address', 'Status Code', 'Title 1', 'H1-1', 'H2-1', 'Crawl Timestamp'],
  inv: ['Product_count'],
  be: ['Full URL']
};
const DOCUMENT_TYPES: DocumentOptionType[] = [
  { label: 'Taxonomy Label Group', value: 'tx' },
  { label: 'Inventory Data', value: 'inv' },
  { label: 'Hierarchical Taxonomy', value: 'hi_tx' },
  { label: 'Page Data', value: 'pd' },
  { label: 'Page Data (Incremental)', value: 'pd--incremental' },
  { label: 'Keyword Data', value: 'kd' },
  { label: 'JetOctopus Page Export', value: 'pe' },
  { label: 'ScreamingFrog Page Export', value: 'sf' },
  { label: 'GSC Bulk Export', value: 'gsc_bulk' },
  { label: 'Hardcoded Links', value: 'hl' },
  { label: 'Botify Export', value: 'be'}
];

/**
 *
 * @param headStr
 * keyword,url,demand,created
 * "URL";"Page";"Distance from Index (DFI)";"Is Indexable";"Non Indexable Reason";"Status Code";"Load Time";"Found at URL";"In Links All";"In Indexable Pages";"Date Crawled Ts"
 * @returns
 */
const headerStr2Array = (headStr: string): string[] => {
  if (!headStr.trim()) return [];
  headStr = headStr.trim();
  let delimiter = ',';
  if (headStr[0] === '"') delimiter = ';';
  let ret = headStr.split(delimiter);
  ret = ret.map((item) => {
    const N = item.length;
    if (item[0] === '"' && item[N - 1] === '"') item = item.slice(1, N - 1);
    return item;
  });
  return ret;
};

export const checkCSV = async (file: File, requiredCols: string[]) => {
  let textReader = new TextReader(file);
  let header = (await textReader.readLine()) as string;
  const headerArr = headerStr2Array(header || '');
  for (const requiredCol of requiredCols) {
    if (headerArr.indexOf(requiredCol) === -1) return false;
  }
  return true;
};

const UploadCSVMain: FC<Props> = ({ indexId }) => {
  const queryClient = useQueryClient();

  const { setSnackbar } = useSnackbar();

  const { mutate: m_upload } = useMutation({
    mutationFn: (body: {
      filename: string;
      type: string;
      incremental?: boolean;
    }) => uploadFeedIngredients(indexId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'FeedIngredient']);
      setSnackbar({ severity: 'success', message: 'Uploaded!' });
    },
    onError: () => {
      setSnackbar({ severity: 'error', message: 'Upload failed' });
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedDocType: DocumentType | 'pd--incremental'
  ) => {
    let incremental = false;
    if (selectedDocType === 'pd--incremental') {
      incremental = true;
      selectedDocType = 'pd';
    }
    const requiredCols = REQUIRED_COLS[selectedDocType];
    const files = e.target.files;
    if (files && files.length > 0) {
      try {
        const file = files[0];
        const check = await checkCSV(file, requiredCols);
        if (!check) {
          e.target.value = '';
          setAnchorEl(null);
          setSnackbar({
            severity: 'error',
            message: `Column "${requiredCols.join(',')}" is not in csv file`,
          });
          return;
        }
        setSnackbar({ message: 'Uploading...', severity: 'info' });
        const filename = file.name;
        const filesize = (file.size / 1024 / 1024).toFixed(2);

        const { presigned_url } = await getDocumentImportPresignedUrl(indexId, {
          filename,
          type: selectedDocType,
        });

        const options = {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress: (progressEvent: { loaded: number }) =>
            setSnackbar({
              message: `Uploading...${(
                progressEvent.loaded /
                1024 /
                1024
              ).toFixed(2)}MB/${filesize}MB`,
              severity: 'info',
            }),
        };
        await axios.put(presigned_url, file, options);
        m_upload({ filename, type: selectedDocType, incremental });
        setSnackbar({ message: 'File Uploaded!', severity: 'info' });
        setAnchorEl(null);
      } catch (err) {
        e.target.value = '';
        setSnackbar({
          message: 'Upload failed, please try again later',
          severity: 'error',
        });
        setAnchorEl(null);
      }
    }
    e.target.value = '';
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleClick}>
          Upload <ExpandMoreIcon />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {DOCUMENT_TYPES.map((item) => (
            <Box key={item.value}>
              <Button component="label">
                {item.label}
                <input
                  hidden
                  onChange={(e) => handleUpload(e, item.value)}
                  multiple
                  accept=".csv"
                  type="file"
                />
              </Button>
            </Box>
          ))}
        </Menu>
      </Box>
      <UploadCSVTable indexId={indexId} isProductFeed={false} />
    </Box>
  );
};

export default UploadCSVMain;
