import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import SiteTopicReportTable from './SiteTopicReportTable';

const SiteTopicMain: FC = () => {
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Typography sx={{ my: 4 }} variant="h3">
        Site topic reports
      </Typography>
      <SiteTopicReportTable />
    </Box>
  );
};

export default SiteTopicMain;
